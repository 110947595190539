<template>
    <div>
<!--        <div class="class-body">-->
<!--            <div :class="{-->
<!--                'class-card':true,-->
<!--                's-card':selectClass == item.key_name,-->
<!--            }" v-for="item in classList" :key="item.category" @click="getClassName(item.key_name)">-->
<!--                {{item.key_name}}-->
<!--            </div>-->
<!--        </div>-->
        <adContent></adContent>
        <div class="game-list" v-for="(items,key) in gameList" :key="items.title">
            <div class="game-header">
                <div class="g-h-l">{{key}}</div>
                <div @click="getClassName(key)" class="g-h-r">more+</div>
            </div>
            <div class="game-card" v-for="item in items" :key="item.id" @click="goDetails(item.id)">
                <div class="card-img">
                    <img :src="item.logo" alt="">
                    <div class="play-btn">PLAY</div>
                </div>
                <div class="card-title">{{item.title}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import navMenu from '@/utils/navMenu'
import { getClassList, getGameList } from "@/api/games"
import adContent from "@/components/adContent/index.vue";

    export default {
        components:{
            adContent
        },
        data() {
            return {
                gameList: [],
                classList: [],
                selectClass: 'New'
            }
        },
        mounted() {
            eventBus.$on('searchgame',name=>{
                this.searchGame(name)
            })
            let name = this.$route.params.searchtext;
            if(name){
                this.searchGame(name);
            }else{
                this.getList();
            }
            this.getClassList();
        },
        methods: {
            async getList(){
                let res = await getGameList();
                this.gameList = res;
            },
            async getClassList(){
                let classlist = await getClassList();
                classlist.forEach(el => {
                    navMenu.forEach(item=>{
                        if(item.name==el.key_name){
                            el.url = item.url
                        }
                    })
                });
                this.classList = classlist;
            },
            async getClassName(key){
                this.$router.push({
                    path:'/selectGame',
                    query: {
                        id: 'more',
                        key
                    }
                })
            },
            goDetails(id){
                this.$router.push({
                    path:'/details',
                    query:{
                        id
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
.class-body{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 54px;
    background: #FFF;
    border-radius: 18px;
    padding: 10px;
    box-sizing: border-box;
    @media (max-width: 768px) {
        .class-card{
            margin-bottom: 10px;
        }
    }
    .class-card{
        padding: 10px 19px;
        border-radius: 9px;
        background: #F1F1F1;
        box-sizing: border-box;
        text-align: center;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 12px;
        color: #000;
        font-style: normal;
        text-transform: none;
    }
    .s-card{
        border: 1px solid #000;
    }
}
.game-list{
    margin-top: 42px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .game-header{
        width: 100%;
        height: 50px;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: Inria Sans, Inria Sans;
        .g-h-l{
            font-weight: 400;
            font-size: 21px;
            color: #000000;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }
        .g-h-r{
            font-weight: 400;
            font-size: 21px;
            color: #464646;
            text-align: right;
            font-style: normal;
            text-transform: none;
        }
    }
    .game-card{
        width: 172px;
        background: #FFF;
        border-radius: 9px;
        padding: 10px 0 13px;
        box-sizing: border-box;
        margin-bottom: 10px;
        .card-img{
            margin: 0 auto;
            width: 144px;
            height: 144px;
            border: 9px;
            border-radius: 16px;
            overflow: hidden;
            img{
                width: 144px;
                height: 144px;
            }
        }
        .card-title{
            height: 18px;
            margin-top: 13px;
            font-family: Inter, Inter;
            font-weight: bold;
            font-size: 14px;
            color: #000;
            line-height: 19px;
            text-align: center;
            font-style: normal;
            text-transform: none;

            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>